import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content.js'
import Layout from '../components/Layout.js'
import Accordion from '../components/Accordion'
import Gallery from '../components/Gallery'
import GoogleMap from '../components/GoogleMap'

// Export Template for use in CMS preview
export const ComponentsPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  section1,
  section2,
  section3,
  section4,
  accordion,
  gallery,
  locations
}) => (
  <main>
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />
    <section className="section">
      <div className="container">
        <h2>Generell informasjon</h2>
        <Content source={section1} />
      </div>
    </section>

    <section className="section button-banner">
      <div className="container">
        <a href="https://signup.eqtiming.no/?Event=langfjordrennet">
          {section2}
        </a>
      </div>
    </section>

    <section className="section accordion">
      <div className="container">
        <h2>Nyttig å vite</h2>
        <Accordion items={accordion} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h2>Løypekart</h2>
        <Content source={section3} />
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h2>Bilder fra rennet</h2>
        <Gallery images={gallery} />
        <div className="spacer">
          <a
            className="internal-link"
            href="https://www.facebook.com/langfjordrennet/"
          >
            Se flere bilder på vår Facebook-side
          </a>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h2>Arrangørene</h2>
        <Content source={section4} />
      </div>
    </section>

    <GoogleMap locations={locations} />
  </main>
)

const ComponentsPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ComponentsPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ComponentsPage

export const pageQuery = graphql`
  query ComponentsPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        section1
        section2
        section3
        section4
        videoPoster
        video
        locations {
          mapLink
          lat
          lng
        }
        accordion {
          title
          description
        }
      }
    }
  }
`
